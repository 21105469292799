// src/components/OrderDetailsPage/OrderDetailsPage.jsx

import React, { useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { OrderContext } from '../../context/OrderContext';
import OrderTrackingInterface from './OrderTrackingInterface';

const OrderDetailsPage = () => {
	const { orderId } = useParams();
	const { kitchenOrders } = useContext(OrderContext);

	// Find the order by orderId
	const order = kitchenOrders.find((o) => o.orderId === parseInt(orderId));

	if (!order) {
		return (
			<div className="container mt-5">
				<h2>Order Not Found</h2>
				<Link to="/kitchen" className="btn btn-primary mt-3">
					Back to Dashboard
				</Link>
			</div>
		);
	}

	return (
		<div className="container mt-5">
			<h2>Order Details - Order #{order.orderId}</h2>
			<p><strong>Table Number:</strong> {order.tableNumber}</p>
			<p><strong>Customer Name:</strong> {order.customerName}</p>
			<p><strong>Customer Allergies:</strong> {order.customerAllergies.join(', ') || 'None'}</p>

			<h4>Items Ordered:</h4>
			<ul className="list-group list-group-flush">
				{order.items.map((item) => (
					<li key={item.itemId} className="list-group-item d-flex justify-content-between align-items-center">
						<div>
							<strong>{item.name}</strong> - {item.customization}
						</div>
						<div>
							<span className={`badge ${item.ordered ? 'bg-success' : 'bg-warning text-dark'}`}>
								{item.ordered ? 'Ordered' : 'Pending'}
							</span>
						</div>
					</li>
				))}
			</ul>

			<OrderTrackingInterface order={order} />

			<Link to="/kitchen" className="btn btn-secondary mt-3">
				Back to Dashboard
			</Link>
		</div>
	);
};

export default OrderDetailsPage;
