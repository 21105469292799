// src/components/AdminDashboard/CustomerNotifications.jsx

import React, { useEffect, useState } from 'react';
import api from '../../utils/api';

const CustomerNotifications = () => {
	const [tables, setTables] = useState([]);
	const [message, setMessage] = useState('');
	const [selectedTableId, setSelectedTableId] = useState('');
	const [notifications, setNotifications] = useState([]);

	useEffect(() => {
		// Fetch tables and notifications on component mount
		fetchTables();
		fetchNotifications();
	}, []);

	const fetchTables = async () => {
		try {
			const response = await api.get('/admin/tables');
			setTables(response.data);
		} catch (error) {
			console.error('Error fetching tables:', error);
		}
	};

	const fetchNotifications = async () => {
		try {
			const response = await api.get('/admin/notifications');
			setNotifications(response.data);
		} catch (error) {
			console.error('Error fetching notifications:', error);
		}
	};

	const handleSendNotification = async (e) => {
		e.preventDefault();
		if (selectedTableId && message.trim()) {
			try {
				await api.post('/admin/notifications', {
					recipientId: selectedTableId,
					recipientModel: 'Table',
					message,
				});
				alert('Notification sent successfully.');
				setMessage('');
				setSelectedTableId('');
				fetchNotifications(); // Refresh notifications
			} catch (error) {
				console.error('Error sending notification:', error);
				alert('Failed to send notification.');
			}
		}
	};

	return (
		<div>
			<h2>Customer Notifications</h2>
			{/* Send Notification Form */}
			<form onSubmit={handleSendNotification} className="mb-4">
				<div className="row g-3 align-items-center">
					<div className="col-md-4">
						<select
							className="form-select"
							value={selectedTableId}
							onChange={(e) => setSelectedTableId(e.target.value)}
							required
							aria-required="true"
							aria-label="Select Table"
						>
							<option value="" disabled>
								Select Table
							</option>
							{tables.map((table) => (
								<option key={table._id} value={table._id}>
									{table.tableNumber}
								</option>
							))}
						</select>
					</div>
					<div className="col-md-6">
						<input
							type="text"
							className="form-control"
							placeholder="Enter Notification Message"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							required
							aria-required="true"
						/>
					</div>
					<div className="col-md-2">
						<button type="submit" className="btn btn-primary w-100">
							Send
						</button>
					</div>
				</div>
			</form>

			{/* Sent Notifications List */}
			<h4>Sent Notifications</h4>
			<table className="table table-bordered">
				<thead>
					<tr>
						<th>Table</th>
						<th>Message</th>
						<th>Timestamp</th>
					</tr>
				</thead>
				<tbody>
					{notifications.length > 0 ? (
						notifications.map((notification) => (
							<tr key={notification._id}>
								<td>{`Table ${notification.recipient.tableNumber}`}</td>
								<td>{notification.message}</td>
								<td>{new Date(notification.sentAt).toLocaleString()}</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="3" className="text-center">
								No notifications sent yet.
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default CustomerNotifications;
