// src/components/ServerDashboard/TableAccordion.jsx

import React, { useContext } from 'react';
import { OrderContext } from '../../context/OrderContext';
import OrderItem from './OrderItem';

const TableAccordion = ({ table }) => {
	const { markItemAsOrdered, markTableAsOrdered, resolveReport } = useContext(OrderContext);

	const handleMarkTable = () => {
		markTableAsOrdered(table.tableId);
	};

	return (
		<div className="accordion-item">
			<h2 className="accordion-header" id={`heading-${table.tableId}`}>
				<button
					className="accordion-button collapsed"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target={`#collapse-${table.tableId}`}
					aria-expanded="false"
					aria-controls={`collapse-${table.tableId}`}
				>
					{table.tableNumber}
				</button>
			</h2>
			<div
				id={`collapse-${table.tableId}`}
				className="accordion-collapse collapse"
				aria-labelledby={`heading-${table.tableId}`}
				data-bs-parent="#tablesAccordion"
			>
				<div className="accordion-body">
					<h5>Orders:</h5>
					{table.orders.length === 0 ? (
						<p>No orders for this table.</p>
					) : (
						table.orders.map((order) => (
							<div key={order.orderId} className="mb-3">
								<strong>{order.customerName}</strong>
								<ul className="list-group list-group-flush">
									{order.items.map((item) => (
										<OrderItem
											key={item.itemId}
											tableId={table.tableId}
											orderId={order.orderId}
											item={item}
											markItemAsOrdered={markItemAsOrdered}
										/>
									))}
								</ul>
							</div>
						))
					)}
					<button className="btn btn-success mt-3" onClick={handleMarkTable}>
						Mark Table as Ordered
					</button>
				</div>
			</div>
		</div>
	);
};

export default TableAccordion;
