// src/components/KitchenDashboard/OrderAccordion.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import api from '../../utils/api';
import OrderStatusTag from './OrderStatusTag';

const OrderAccordion = ({ order }) => {
	const handleStatusChange = async (e) => {
		const newStatus = e.target.value;
		try {
			await api.put(`/kitchen/orders/${order.kitchenOrderId}/status`, { status: newStatus });
			// Optionally, trigger a refresh or update state
			window.location.reload(); // Simple way to refresh; consider a better state management approach
		} catch (error) {
			console.error('Error updating order status:', error.response?.data?.msg || error.message);
			alert('Failed to update order status.');
		}
	};

	return (
		<div className="accordion-item">
			<h2 className="accordion-header" id={`heading-${order.kitchenOrderId}`}>
				<button
					className="accordion-button collapsed"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target={`#collapse-${order.kitchenOrderId}`}
					aria-expanded="false"
					aria-controls={`collapse-${order.kitchenOrderId}`}
				>
					{order.tableNumber} - Order #{order.orderId}
					<OrderStatusTag status={order.status} />
				</button>
			</h2>
			<div
				id={`collapse-${order.kitchenOrderId}`}
				className="accordion-collapse collapse"
				aria-labelledby={`heading-${order.kitchenOrderId}`}
				data-bs-parent="#kitchenOrdersAccordion"
			>
				<div className="accordion-body">
					<h5>Items Ordered:</h5>
					<ul className="list-group list-group-flush">
						{order.items.map((item) => (
							<li key={item.itemId} className="list-group-item">
								<strong>{item.name}</strong> - {item.customization}
							</li>
						))}
					</ul>
					<p className="mt-3">
						<strong>Customer Allergies:</strong> {order.customerAllergies.join(', ') || 'None'}
					</p>
					<div className="mt-3">
						<label htmlFor={`status-${order.kitchenOrderId}`} className="form-label">
							Update Order Status:
						</label>
						<select
							id={`status-${order.kitchenOrderId}`}
							className="form-select"
							value={order.status}
							onChange={handleStatusChange}
							aria-label="Order Status"
						>
							<option value="Pending">Pending</option>
							<option value="Preparing">Preparing</option>
							<option value="Ready">Ready</option>
							<option value="Delivered">Delivered</option>
						</select>
					</div>
					<Link to={`/order/${order.orderId}`} className="btn btn-primary mt-3">
						View Order Details
					</Link>
				</div>
			</div>
		</div>
	);
};

export default OrderAccordion;
