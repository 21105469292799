// src/components/ServerDashboard/TableList.jsx

import React, { useContext } from 'react';
import { OrderContext } from '../../context/OrderContext';
import TableAccordion from './TableAccordion';

const TableList = () => {
	const { assignedTables } = useContext(OrderContext);

	return (
		<div className="mt-4">
			<h2>Assigned Tables</h2>
			{assignedTables.length === 0 ? (
				<p>No tables assigned to you.</p>
			) : (
				<div className="accordion" id="tablesAccordion">
					{assignedTables.map((table) => (
						<TableAccordion key={table.tableId} table={table} />
					))}
				</div>
			)}
		</div>
	);
};

export default TableList;
