// src/context/OrderContext.jsx

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';

export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
	// Import user from AuthContext
	const { user } = React.useContext(AuthContext);

	// Existing states
	const [selectedItems, setSelectedItems] = useState([]);
	const [allergies, setAllergies] = useState([]);
	const [orderStatus, setOrderStatus] = useState('Pending');
	const [error, setError] = useState(null);

	// Server and Kitchen states
	const [assignedTables, setAssignedTables] = useState([]);
	const [kitchenOrders, setKitchenOrders] = useState([]);

	// Admin states
	const [inventory, setInventory] = useState([]);
	const [menuItems, setMenuItems] = useState([]);

	// Fetch data based on user role
	useEffect(() => {
		if (user) {
			if (user.role === 'server') {
				fetchAssignedTables();
			} else if (user.role === 'kitchen') {
				fetchKitchenOrders();
			} else if (user.role === 'admin') {
				fetchInventory();
				fetchMenuItems();
			}
		}
	}, [user]);

	// Functions for customer side
	const addItem = (item) => {
		setSelectedItems((prevItems) => {
			if (prevItems.find((i) => i.menuItemId === item.menuItemId)) {
				return prevItems; // Avoid duplicates
			}
			return [...prevItems, item];
		});
	};

	const removeItem = (menuItemId) => {
		setSelectedItems((prevItems) =>
			prevItems.filter((item) => item.menuItemId !== menuItemId)
		);
	};

	const toggleAllergy = (allergy) => {
		setAllergies((prevAllergies) =>
			prevAllergies.includes(allergy)
				? prevAllergies.filter((a) => a !== allergy)
				: [...prevAllergies, allergy]
		);
	};

	const submitOrder = async (tableId) => {
		try {
			const orderData = {
				tableId,
				items: selectedItems.map((item) => ({
					menuItemId: item.menuItemId,
					quantity: item.quantity || 1,
					customization: item.customization || '',
				})),
				allergies,
			};

			const res = await axios.post('/api/customer/orders', orderData);
			setOrderStatus('Pending');
			// Clear selected items and allergies after successful order
			setSelectedItems([]);
			setAllergies([]);
			return res.data;
		} catch (err) {
			setError(err.response.data.msg || 'Failed to submit order');
			console.error('Order submission error:', err);
		}
	};

	const reportIssue = async (orderId, issue) => {
		try {
			const res = await axios.post(`/api/customer/orders/${orderId}/issues`, {
				issue,
			});
			return res.data;
		} catch (err) {
			setError(err.response.data.msg || 'Failed to report issue');
			console.error('Issue reporting error:', err);
		}
	};

	// Fetch assigned tables for server
	const fetchAssignedTables = async () => {
		try {
			const res = await axios.get('/api/server/assignedTables');
			setAssignedTables(res.data);
		} catch (err) {
			console.error('Error fetching assigned tables:', err);
		}
	};

	// Server functions
	const markItemAsOrdered = async (orderId, itemId) => {
		try {
			const res = await axios.put(`/api/server/orders/${orderId}/items/${itemId}`, {
				ordered: true,
			});
			// Update local state or refetch assigned tables
			fetchAssignedTables();
		} catch (err) {
			console.error('Error marking item as ordered:', err);
		}
	};

	const markTableAsOrdered = async (tableId) => {
		try {
			// Implement API call if needed
			fetchAssignedTables();
		} catch (err) {
			console.error('Error marking table as ordered:', err);
		}
	};

	const resolveReport = async (tableId, reportId) => {
		try {
			// Implement API call to resolve report
			fetchAssignedTables();
		} catch (err) {
			console.error('Error resolving report:', err);
		}
	};

	// Fetch kitchen orders
	const fetchKitchenOrders = async () => {
		try {
			const res = await axios.get(`/api/kitchen/kitchens/${user.assignedKitchens[0]}/orders`);
			setKitchenOrders(res.data);
		} catch (err) {
			console.error('Error fetching kitchen orders:', err);
		}
	};

	// Kitchen functions
	const updateOrderStatus = async (orderId, newStatus) => {
		try {
			const res = await axios.put(`/api/orders/${orderId}/status`, {
				status: newStatus,
			});
			// Update local state or refetch kitchen orders
			fetchKitchenOrders();
		} catch (err) {
			console.error('Error updating order status:', err);
		}
	};

	// Fetch inventory items
	const fetchInventory = async () => {
		try {
			const res = await axios.get('/api/admin/inventory');
			setInventory(res.data);
		} catch (err) {
			console.error('Error fetching inventory:', err);
		}
	};

	// Admin functions - Inventory Management
	const addInventoryItem = async (item) => {
		try {
			const res = await axios.post('/api/admin/inventory', item);
			setInventory((prevInventory) => [...prevInventory, res.data]);
		} catch (err) {
			console.error('Error adding inventory item:', err);
		}
	};

	const updateInventoryItem = async (itemId, updatedItem) => {
		try {
			const res = await axios.put(`/api/admin/inventory/${itemId}`, updatedItem);
			setInventory((prevInventory) =>
				prevInventory.map((item) =>
					item._id === itemId ? res.data : item
				)
			);
		} catch (err) {
			console.error('Error updating inventory item:', err);
		}
	};

	const deleteInventoryItem = async (itemId) => {
		try {
			await axios.delete(`/api/admin/inventory/${itemId}`);
			setInventory((prevInventory) =>
				prevInventory.filter((item) => item._id !== itemId)
			);
		} catch (err) {
			console.error('Error deleting inventory item:', err);
		}
	};

	// Fetch menu items
	const fetchMenuItems = async () => {
		try {
			const res = await axios.get('/api/admin/meals');
			setMenuItems(res.data);
		} catch (err) {
			console.error('Error fetching menu items:', err);
		}
	};

	// Admin functions - Meal Management
	const addMeal = async (meal) => {
		try {
			const res = await axios.post('/api/admin/meals', meal);
			setMenuItems((prevMenuItems) => [...prevMenuItems, res.data]);
		} catch (err) {
			console.error('Error adding meal:', err);
		}
	};

	const updateMeal = async (mealId, updatedMeal) => {
		try {
			const res = await axios.put(`/api/admin/meals/${mealId}`, updatedMeal);
			setMenuItems((prevMenuItems) =>
				prevMenuItems.map((meal) =>
					meal._id === mealId ? res.data : meal
				)
			);
		} catch (err) {
			console.error('Error updating meal:', err);
		}
	};

	const deleteMeal = async (mealId) => {
		try {
			await axios.delete(`/api/admin/meals/${mealId}`);
			setMenuItems((prevMenuItems) =>
				prevMenuItems.filter((meal) => meal._id !== mealId)
			);
		} catch (err) {
			console.error('Error deleting meal:', err);
		}
	};

	// Admin functions - Kitchen and Table Management
	const addKitchen = async (kitchen) => {
		try {
			const res = await axios.post('/api/admin/kitchens', kitchen);
			// Update local state if you store kitchens
		} catch (err) {
			console.error('Error adding kitchen:', err);
		}
	};

	const updateKitchen = async (kitchenId, updatedKitchen) => {
		try {
			const res = await axios.put(`/api/admin/kitchens/${kitchenId}`, updatedKitchen);
			// Update local state if you store kitchens
		} catch (err) {
			console.error('Error updating kitchen:', err);
		}
	};

	const deleteKitchen = async (kitchenId) => {
		try {
			await axios.delete(`/api/admin/kitchens/${kitchenId}`);
			// Update local state if you store kitchens
		} catch (err) {
			console.error('Error deleting kitchen:', err);
		}
	};

	const addTable = async (table) => {
		try {
			const res = await axios.post('/api/admin/tables', table);
			// Update local state if you store tables
		} catch (err) {
			console.error('Error adding table:', err);
		}
	};

	const updateTable = async (tableId, updatedTable) => {
		try {
			const res = await axios.put(`/api/admin/tables/${tableId}`, updatedTable);
			// Update local state if you store tables
		} catch (err) {
			console.error('Error updating table:', err);
		}
	};

	const deleteTable = async (tableId) => {
		try {
			await axios.delete(`/api/admin/tables/${tableId}`);
			// Update local state if you store tables
		} catch (err) {
			console.error('Error deleting table:', err);
		}
	};

	const assignServerToTable = async (serverId, tableId) => {
		try {
			// Implement API call to assign server to table
			// Update local state if necessary
		} catch (err) {
			console.error('Error assigning server to table:', err);
		}
	};

	return (
		<OrderContext.Provider
			value={{
				// Customer-related
				selectedItems,
				addItem,
				removeItem,
				allergies,
				toggleAllergy,
				orderStatus,
				submitOrder,
				reportIssue,
				error,
				setError,
				// Server-related
				assignedTables,
				markItemAsOrdered,
				markTableAsOrdered,
				resolveReport,
				// Kitchen-related
				kitchenOrders,
				updateOrderStatus,
				fetchKitchenOrders,
				// Admin-related
				inventory,
				addInventoryItem,
				updateInventoryItem,
				deleteInventoryItem,
				menuItems,
				addMeal,
				updateMeal,
				deleteMeal,
				// Kitchen and Table Management
				addKitchen,
				updateKitchen,
				deleteKitchen,
				addTable,
				updateTable,
				deleteTable,
				assignServerToTable,
			}}
		>
			{children}
		</OrderContext.Provider>
	);
};
