// src/components/KitchenDashboard/KitchenDashboard.jsx

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../utils/api';
import OrderList from './OrderList';
import ReportAlerts from '../ServerDashboard/ReportAlerts'; // Reusing the ReportAlerts component

const KitchenDashboard = () => {
	const { kitchenId } = useParams();
	const [kitchenOrders, setKitchenOrders] = useState([]);
	const [errorMsg, setErrorMsg] = useState('');

	useEffect(() => {
		fetchKitchenOrders();
		// Optionally, fetch report alerts if needed
	}, [kitchenId]);

	const fetchKitchenOrders = async () => {
		try {
			const res = await api.get(`/admin/kitchen/${kitchenId}/orders`);
			setKitchenOrders(res.data);
		} catch (error) {
			console.error('Error fetching kitchen orders:', error.response?.data?.msg || error.message);
			setErrorMsg(error.response?.data?.msg || 'Failed to fetch kitchen orders.');
		}
	};

	return (
		<div className="container-fluid p-4">
			<h1>Kitchen Dashboard</h1>
			<hr />
			{errorMsg && (
				<div className="alert alert-danger alert-dismissible fade show" role="alert">
					{errorMsg}
					<button type="button" className="btn-close" onClick={() => setErrorMsg('')} aria-label="Close"></button>
				</div>
			)}
			<ReportAlerts /> {/* Optional: Display reports if needed */}
			<OrderList orders={kitchenOrders} />
		</div>
	);
};

export default KitchenDashboard;
