// src/context/AuthContext.jsx

import React, { createContext, useState, useEffect } from 'react';
import api from '../utils/api'; // Use the api utility

// Create the Auth Context
export const AuthContext = createContext();

// Auth Provider Component
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);

  // Load user on app load
  useEffect(() => {
    if (isAuthenticated && !user) {
      loadUser();
    }
  }, [isAuthenticated]);

  // Load user data
  const loadUser = async () => {
    try {
      const res = await api.get('/auth/user');
      setUser(res.data);
      setIsAuthenticated(true);
      localStorage.setItem('user', JSON.stringify(res.data));
    } catch (err) {
      console.error('Error loading user:', err);
      logout();
    }
  };

  // Login function
  const login = async (emailOrUsername, password) => {
    try {
      const res = await api.post('/auth/login', { emailOrUsername, password });
      const { token, user } = res.data;
      localStorage.setItem('token', token);
      api.defaults.headers.common['x-auth-token'] = token;
      setUser(user);
      setIsAuthenticated(true);
      localStorage.setItem('user', JSON.stringify(user));
    } catch (err) {
      console.error('Login error:', err.response?.data?.msg);
      throw err.response?.data?.msg || 'Login failed';
    }
  };

  // Logout function
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    delete api.defaults.headers.common['x-auth-token'];
    setIsAuthenticated(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
