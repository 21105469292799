import React from 'react';

const OrderStatus = ({ orderStatus }) => {
	const getStatusVariant = (status) => {
		switch (status) {
			case 'Pending':
				return 'warning';
			case 'Completed':
				return 'success';
			case 'Cancelled':
				return 'danger';
			default:
				return 'secondary';
		}
	};

	return (
		<div className="mt-3">
			<h5>
				Order Status: <span className={`badge bg-${getStatusVariant(orderStatus)}`}>{orderStatus}</span>
			</h5>
		</div>
	);
};

export default OrderStatus;
