// src/components/AdminDashboard/KitchenTableManagement.jsx

import React, { useEffect, useState } from 'react';
import api from '../../utils/api';

const KitchenTableManagement = () => {
	const [kitchens, setKitchens] = useState([]);
	const [tables, setTables] = useState([]);
	const [newKitchen, setNewKitchen] = useState({ name: '' });
	const [newTable, setNewTable] = useState({ tableNumber: '', assignedKitchen: '' });
	const [errorMsg, setErrorMsg] = useState('');
	const [successMsg, setSuccessMsg] = useState('');

	useEffect(() => {
		fetchKitchens();
		fetchTables();
	}, []);

	const fetchKitchens = async () => {
		try {
			const response = await api.get('/admin/kitchens');
			setKitchens(response.data);
		} catch (error) {
			console.error('Error fetching kitchens:', error.response?.data?.msg || error.message);
			setErrorMsg(error.response?.data?.msg || 'Failed to fetch kitchens.');
		}
	};

	const fetchTables = async () => {
		try {
			const response = await api.get('/admin/tables');
			setTables(response.data);
		} catch (error) {
			console.error('Error fetching tables:', error.response?.data?.msg || error.message);
			setErrorMsg(error.response?.data?.msg || 'Failed to fetch tables.');
		}
	};

	const handleAddKitchen = async (e) => {
		e.preventDefault();
		setErrorMsg('');
		setSuccessMsg('');
		if (newKitchen.name.trim()) {
			try {
				await api.post('/admin/kitchens', newKitchen);
				setNewKitchen({ name: '' });
				setSuccessMsg('Kitchen added successfully.');
				fetchKitchens();
			} catch (error) {
				console.error('Error adding kitchen:', error.response?.data?.msg || error.message);
				setErrorMsg(error.response?.data?.msg || 'Failed to add kitchen.');
			}
		} else {
			setErrorMsg('Kitchen name cannot be empty.');
		}
	};

	const handleDeleteKitchen = async (kitchenId) => {
		if (window.confirm('Are you sure you want to delete this kitchen? All associated tables and staff will be unassigned.')) {
			try {
				await api.delete(`/admin/kitchens/${kitchenId}`);
				setSuccessMsg('Kitchen deleted successfully.');
				fetchKitchens();
				fetchTables();
			} catch (error) {
				console.error('Error deleting kitchen:', error.response?.data?.msg || error.message);
				setErrorMsg(error.response?.data?.msg || 'Failed to delete kitchen.');
			}
		}
	};

	const handleAddTable = async (e) => {
		e.preventDefault();
		setErrorMsg('');
		setSuccessMsg('');
		const { tableNumber, assignedKitchen } = newTable;
		if (tableNumber.trim() && assignedKitchen) {
			try {
				await api.post('/admin/tables', newTable);
				setNewTable({ tableNumber: '', assignedKitchen: '' });
				setSuccessMsg('Table added and assigned successfully.');
				fetchTables();
				fetchKitchens();
			} catch (error) {
				console.error('Error adding table:', error.response?.data?.msg || error.message);
				setErrorMsg(error.response?.data?.msg || 'Failed to add table.');
			}
		} else {
			setErrorMsg('Table number and assigned kitchen are required.');
		}
	};

	const handleDeleteTable = async (tableId) => {
		if (window.confirm('Are you sure you want to delete this table?')) {
			try {
				await api.delete(`/admin/tables/${tableId}`);
				setSuccessMsg('Table deleted successfully.');
				fetchTables();
				fetchKitchens();
			} catch (error) {
				console.error('Error deleting table:', error.response?.data?.msg || error.message);
				setErrorMsg(error.response?.data?.msg || 'Failed to delete table.');
			}
		}
	};

	return (
		<div>
			<h2>Kitchen & Table Management</h2>

			{/* Feedback Messages */}
			{errorMsg && (
				<div className="alert alert-danger alert-dismissible fade show" role="alert">
					{errorMsg}
					<button type="button" className="btn-close" onClick={() => setErrorMsg('')} aria-label="Close"></button>
				</div>
			)}
			{successMsg && (
				<div className="alert alert-success alert-dismissible fade show" role="alert">
					{successMsg}
					<button type="button" className="btn-close" onClick={() => setSuccessMsg('')} aria-label="Close"></button>
				</div>
			)}

			{/* Add New Kitchen */}
			<form onSubmit={handleAddKitchen} className="mb-4">
				<h4>Add New Kitchen</h4>
				<div className="row g-3 align-items-center">
					<div className="col-md-10">
						<input
							type="text"
							className="form-control"
							placeholder="Kitchen Name"
							value={newKitchen.name}
							onChange={(e) => setNewKitchen({ ...newKitchen, name: e.target.value })}
							required
							aria-required="true"
						/>
					</div>
					<div className="col-md-2">
						<button type="submit" className="btn btn-primary w-100">
							Add Kitchen
						</button>
					</div>
				</div>
			</form>

			{/* Add New Table */}
			<form onSubmit={handleAddTable} className="mb-4">
				<h4>Add New Table</h4>
				<div className="row g-3 align-items-center">
					<div className="col-md-4">
						<input
							type="text"
							className="form-control"
							placeholder="Table Number"
							value={newTable.tableNumber}
							onChange={(e) => setNewTable({ ...newTable, tableNumber: e.target.value })}
							required
							aria-required="true"
						/>
					</div>
					<div className="col-md-4">
						<select
							className="form-select"
							value={newTable.assignedKitchen}
							onChange={(e) => setNewTable({ ...newTable, assignedKitchen: e.target.value })}
							required
							aria-required="true"
							aria-label="Select Kitchen"
						>
							<option value="" disabled>
								Select Kitchen
							</option>
							{kitchens.map((kitchen) => (
								<option key={kitchen._id} value={kitchen._id}>
									{kitchen.name}
								</option>
							))}
						</select>
					</div>
					<div className="col-md-4">
						<button type="submit" className="btn btn-primary w-100">
							Add Table
						</button>
					</div>
				</div>
			</form>

			{/* Kitchens List */}
			<h4>Kitchens</h4>
			<table className="table table-bordered">
				<thead>
					<tr>
						<th>Kitchen ID</th>
						<th>Name</th>
						<th>Assigned Staff</th>
						<th>Assigned Tables</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{kitchens.length > 0 ? (
						kitchens.map((kitchen) => (
							<tr key={kitchen._id}>
								<td>{kitchen._id}</td>
								<td>{kitchen.name}</td>
								<td>
									{kitchen.assignedStaff && kitchen.assignedStaff.length > 0
										? kitchen.assignedStaff.join(', ')
										: 'No staff assigned'}
								</td>
								<td>
									{kitchen.assignedTables && kitchen.assignedTables.length > 0
										? kitchen.assignedTables.join(', ')
										: 'No tables assigned'}
								</td>
								<td>
									<button
										className="btn btn-sm btn-danger"
										onClick={() => handleDeleteKitchen(kitchen._id)}
									>
										Delete
									</button>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="5" className="text-center">
								No kitchens available.
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{/* Tables List */}
			<h4>Tables</h4>
			<table className="table table-bordered">
				<thead>
					<tr>
						<th>Table ID</th>
						<th>Table Number</th>
						<th>Assigned Kitchen</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{tables.length > 0 ? (
						tables.map((table) => (
							<tr key={table._id}>
								<td>{table._id}</td>
								<td>{table.tableNumber}</td>
								<td>{table.assignedKitchen?.name || 'Unassigned'}</td>
								<td>
									<button
										className="btn btn-sm btn-danger"
										onClick={() => handleDeleteTable(table._id)}
									>
										Delete
									</button>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="4" className="text-center">
								No tables available.
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default KitchenTableManagement;
