import React, { useEffect, useState } from 'react';
import api from '../../utils/api';

const ItemList = ({ tableId, selectedItems, onAddItem }) => {
	const [menuItems, setMenuItems] = useState([]);

	useEffect(() => {
		if (tableId) {
			fetchMenuItems(tableId);
		}
	}, [tableId]);

	const fetchMenuItems = async (id) => {
		try {
			const res = await api.get(`/customer/menu/${id}`);
			// We get only available items here. Let's assume we now return all assignedMeals 
			// and handle availability on the client side.
			// If we want all meals including unavailable, the backend should return them all.
			// For now, let's assume backend returns all meals including unavailable.
			setMenuItems(res.data);
		} catch (err) {
			console.error('Error fetching menu items:', err);
		}
	};

	const handleAdd = (item) => {
		onAddItem(item);
	};

	return (
		<div>
			<h3>Available Items</h3>
			<div className="row">
				{menuItems.map((item) => {
					const isSelected = selectedItems.some((i) => i.id === item._id || i._id === item._id);
					const isAvailable = item.quantity > 0; // Determine availability by quantity

					return (
						<div key={item._id} className="col-sm-12 col-md-6 col-lg-4 mb-3">
							<div className="card h-100">
								<div className="card-body d-flex flex-column">
									<h5 className="card-title">{item.name}</h5>
									<p className="card-text">{item.description}</p>
									{isAvailable ? (
										<button
											className="btn btn-primary mt-auto"
											onClick={() => handleAdd(item)}
											disabled={isSelected}
											aria-disabled={isSelected}
											aria-label={`Add ${item.name} to request`}
										>
											{isSelected ? 'Selected' : 'Select Item'}
										</button>
									) : (
										<button
											className="btn btn-secondary mt-auto"
											disabled={true}
											aria-disabled="true"
											aria-label={`${item.name} unavailable`}
										>
											Unavailable
										</button>
									)}
								</div>
							</div>
						</div>
					);
				})}
				{menuItems.length === 0 && <p>No items available for this table.</p>}
			</div>
		</div>
	);
};

export default ItemList;
