// src/components/AdminDashboard/Statistics.jsx

import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto';

const Statistics = () => {
	const [statistics, setStatistics] = useState({
		totalOrders: 0,
		platesServed: 0,
		itemsSold: [],
	});

	useEffect(() => {
		fetchStatistics();
	}, []);

	const fetchStatistics = async () => {
		try {
			const response = await api.get('/admin/statistics');
			setStatistics(response.data);
		} catch (error) {
			console.error('Error fetching statistics:', error);
		}
	};

	const itemsSoldData = {
		labels: statistics.itemsSold.map((item) => item.menuItem),
		datasets: [
			{
				label: 'Items Sold',
				data: statistics.itemsSold.map((item) => item.totalSold),
				backgroundColor: 'rgba(75,192,192,0.6)',
			},
		],
	};

	// Implement other charts as needed

	return (
		<div>
			<h2>Statistics</h2>
			<div className="mt-4">
				<h5>Total Orders: {statistics.totalOrders}</h5>
				<h5>Total Plates Served: {statistics.platesServed}</h5>
			</div>
			<div className="row">
				<div className="col-md-6">
					<h5>Items Sold</h5>
					<Bar data={itemsSoldData} />
				</div>
				{/* Add more charts if needed */}
			</div>
		</div>
	);
};

export default Statistics;
