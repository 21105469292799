// src/components/AdminDashboard/AdminUserManagement.jsx

import React, { useEffect, useState } from 'react';
import api from '../../utils/api';

const AdminUserManagement = () => {
	const [users, setUsers] = useState([]);
	const [newUser, setNewUser] = useState({
		username: '',
		email: '',
		password: '',
		role: 'server',
	});
	const [editingUserId, setEditingUserId] = useState(null);
	const [editingUser, setEditingUser] = useState({
		username: '',
		email: '',
		role: 'server',
	});
	const [errorMsg, setErrorMsg] = useState('');
	const [successMsg, setSuccessMsg] = useState('');

	useEffect(() => {
		fetchUsers();
	}, []);

	// Fetch all users
	const fetchUsers = async () => {
		try {
			const res = await api.get('/admin/users');
			setUsers(res.data);
		} catch (error) {
			console.error('Error fetching users:', error.response?.data?.msg || error.message);
			setErrorMsg(error.response?.data?.msg || 'Failed to fetch users.');
		}
	};

	// Handle input changes for new user form
	const handleNewUserChange = (e) => {
		setNewUser({ ...newUser, [e.target.name]: e.target.value });
	};

	// Handle form submission to create a new user
	const handleCreateUser = async (e) => {
		e.preventDefault();
		setErrorMsg('');
		setSuccessMsg('');

		// Basic validation
		const { username, email, password, role } = newUser;
		if (username == "" || email == "" || password == "" || role == "") {
			setErrorMsg('Please fill in all required fields.');
			return;
		}

		try {
			await api.post('/admin/users', newUser);
			setSuccessMsg('User created successfully.');
			setNewUser({
				username: '',
				email: '',
				password: '',
				role: 'server',
			});
			fetchUsers();
		} catch (error) {
			console.error('Error creating user:', error.response?.data?.msg || error.message);
			setErrorMsg(error.response?.data?.msg || 'Failed to create user.');
		}
	};

	// Handle input changes for editing user form
	const handleEditUserChange = (e) => {
		setEditingUser({ ...editingUser, [e.target.name]: e.target.value });
	};

	// Handle form submission to update an existing user
	const handleUpdateUser = async (e) => {
		e.preventDefault();
		setErrorMsg('');
		setSuccessMsg('');

		const { username, email, role } = editingUser;
		if (username == "" || email == "" || role == "") {
			setErrorMsg('Please fill in all required fields.');
			return;
		}

		try {
			await api.put(`/admin/users/${editingUserId}`, editingUser);
			setSuccessMsg('User updated successfully.');
			setEditingUserId(null);
			setEditingUser({
				username: '',
				email: '',
				role: 'server',
			});
			fetchUsers();
		} catch (error) {
			console.error('Error updating user:', error.response?.data?.msg || error.message);
			setErrorMsg(error.response?.data?.msg || 'Failed to update user.');
		}
	};

	// Handle deleting a user
	const handleDeleteUser = async (userId) => {
		if (window.confirm('Are you sure you want to delete this user?')) {
			setErrorMsg('');
			setSuccessMsg('');
			try {
				await api.delete(`/admin/users/${userId}`);
				setSuccessMsg('User deleted successfully.');
				fetchUsers();
			} catch (error) {
				console.error('Error deleting user:', error.response?.data?.msg || error.message);
				setErrorMsg(error.response?.data?.msg || 'Failed to delete user.');
			}
		}
	};

	// Start editing a user
	const startEditingUser = (user) => {
		setEditingUserId(user._id);
		setEditingUser({
			username: user.username,
			email: user.email,
			role: user.role,
		});
	};

	// Cancel editing
	const cancelEditing = () => {
		setEditingUserId(null);
		setEditingUser({
			username: '',
			email: '',
			role: 'server',
		});
	};

	return (
		<div>
			<h2>User Management</h2>

			{/* Feedback Messages */}
			{errorMsg && (
				<div className="alert alert-danger alert-dismissible fade show" role="alert">
					{errorMsg}
					<button type="button" className="btn-close" onClick={() => setErrorMsg('')} aria-label="Close"></button>
				</div>
			)}
			{successMsg && (
				<div className="alert alert-success alert-dismissible fade show" role="alert">
					{successMsg}
					<button type="button" className="btn-close" onClick={() => setSuccessMsg('')} aria-label="Close"></button>
				</div>
			)}

			{/* Create New User Form */}
			<form onSubmit={handleCreateUser} className="mb-4">
				<h4>Create New User</h4>
				<div className="row g-3 align-items-center">
					<div className="col-md-3">
						<input
							type="text"
							name="username"
							className="form-control"
							placeholder="Username"
							value={newUser.username}
							onChange={handleNewUserChange}
							required
							aria-required="true"
						/>
					</div>
					<div className="col-md-3">
						<input
							type="email"
							name="email"
							className="form-control"
							placeholder="Email"
							value={newUser.email}
							onChange={handleNewUserChange}
							required
							aria-required="true"
						/>
					</div>
					<div className="col-md-3">
						<input
							type="password"
							name="password"
							className="form-control"
							placeholder="Password"
							value={newUser.password}
							onChange={handleNewUserChange}
							required
							aria-required="true"
						/>
					</div>
					<div className="col-md-2">
						<select
							name="role"
							className="form-select"
							value={newUser.role}
							onChange={handleNewUserChange}
							required
							aria-required="true"
						>
							<option value="server">Server</option>
							<option value="kitchen">Kitchen Staff</option>
							<option value="admin">Admin</option>
						</select>
					</div>
					<div className="col-md-1">
						<button type="submit" className="btn btn-primary w-100">
							Add
						</button>
					</div>
				</div>
			</form>

			{/* Users List */}
			<h4>Existing Users</h4>
			<table className="table table-bordered">
				<thead>
					<tr>
						<th>Username</th>
						<th>Email</th>
						<th>Role</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{users.length > 0 ? (
						users.map((user) =>
							editingUserId === user._id ? (
								<tr key={user._id}>
									<td>
										<input
											type="text"
											name="username"
											className="form-control"
											value={editingUser.username}
											onChange={handleEditUserChange}
											required
											aria-required="true"
										/>
									</td>
									<td>
										<input
											type="email"
											name="email"
											className="form-control"
											value={editingUser.email}
											onChange={handleEditUserChange}
											required
											aria-required="true"
										/>
									</td>
									<td>
										<select
											name="role"
											className="form-select"
											value={editingUser.role}
											onChange={handleEditUserChange}
											required
											aria-required="true"
										>
											<option value="server">Server</option>
											<option value="kitchen">Kitchen Staff</option>
											<option value="admin">Admin</option>
										</select>
									</td>
									<td>
										<button className="btn btn-success btn-sm me-2" onClick={handleUpdateUser}>
											Save
										</button>
										<button className="btn btn-secondary btn-sm" onClick={cancelEditing}>
											Cancel
										</button>
									</td>
								</tr>
							) : (
								<tr key={user._id}>
									<td>{user.username}</td>
									<td>{user.email}</td>
									<td>{user.role.charAt(0).toUpperCase() + user.role.slice(1)}</td>
									<td>
										<button className="btn btn-warning btn-sm me-2" onClick={() => startEditingUser(user)}>
											Edit
										</button>
										<button className="btn btn-danger btn-sm" onClick={() => handleDeleteUser(user._id)}>
											Delete
										</button>
									</td>
								</tr>
							)
						)
					) : (
						<tr>
							<td colSpan="4" className="text-center">
								No users found.
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default AdminUserManagement;
