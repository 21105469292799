// src/components/AdminDashboard/AdminDashboard.jsx

import React from 'react';
import { Link, Routes, Route, Navigate } from 'react-router-dom';
import Statistics from './Statistics';
import CustomerNotifications from './CustomerNotifications';
import TableStatusPage from './TableStatusPage';
import MealManagement from './MealManagement'; // Now handles inventory too
import KitchenTableManagement from './KitchenTableManagement';
import AdminUserManagement from './AdminUserManagement';
import AdminKitchenManagement from './AdminKitchenManagement';

const AdminDashboard = () => {
	return (
		<div className="container-fluid p-4">
			<h1>Admin Dashboard</h1>
			<hr />
			<div className="row">
				<div className="col-md-3">
					<div className="list-group">
						<Link to="statistics" className="list-group-item list-group-item-action">Statistics</Link>
						<Link to="notifications" className="list-group-item list-group-item-action">Customer Notifications</Link>
						<Link to="table-status" className="list-group-item list-group-item-action">Table Status</Link>
						<Link to="meal-management" className="list-group-item list-group-item-action">Meal Management</Link>
						<Link to="kitchen-table-management" className="list-group-item list-group-item-action">Kitchen & Table Management</Link>
						<Link to="user-management" className="list-group-item list-group-item-action">User Management</Link>
						<Link to="kitchen-management" className="list-group-item list-group-item-action">Kitchen Management</Link>
					</div>
				</div>
				<div className="col-md-9">
					<Routes>
						<Route path="/" element={<Navigate to="statistics" replace />} />
						<Route path="statistics" element={<Statistics />} />
						<Route path="notifications" element={<CustomerNotifications />} />
						<Route path="table-status" element={<TableStatusPage />} />
						<Route path="meal-management" element={<MealManagement />} />
						<Route path="kitchen-table-management" element={<KitchenTableManagement />} />
						<Route path="user-management" element={<AdminUserManagement />} />
						<Route path="kitchen-management" element={<AdminKitchenManagement />} />
						<Route path="*" element={<h2>Admin Page Not Found</h2>} />
					</Routes>
				</div>
			</div>
		</div>
	);
};

export default AdminDashboard;
