// src/components/Shared/NavigationBar.jsx

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const NavigationBar = () => {
  const { isAuthenticated, user, logout } = useContext(AuthContext);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <image src="/images/logo.png" width="30" height="30" className="d-inline-block align-top" alt="Offer7" />
          Offer7
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {!isAuthenticated ? (
              <>
                {/* For customers, minimal navigation
                <li className="nav-item">
                  <Link className="nav-link" to="/customer">
                    Menu
                  </Link>
                </li> */}
                {/* Staff Registration
                <li className="nav-item">
                  <Link className="nav-link" to="/register">
                    Register
                  </Link>
                </li> */}
                {/* Staff Login */}
                <li className="nav-item">
                  <Link className="nav-link" to="/login">
                    Staff Login
                  </Link>
                </li>
              </>
            ) : (
              <>
                {/* Authenticated Staff Links */}
                {user.role === 'admin' && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin">
                      Admin Dashboard
                    </Link>
                  </li>
                )}
                {user.role === 'server' && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/server">
                      Server Dashboard
                    </Link>
                  </li>
                )}
                {user.role === 'kitchen' && user.assignedKitchens && user.assignedKitchens.length > 0 && (
                  <li className="nav-item">
                    <Link className="nav-link" to={`/kitchen/${user.assignedKitchens[0]}`}>
                      Kitchen Dashboard
                    </Link>
                  </li>
                )}
                {/* Add more role-based links here if necessary */}
                <li className="nav-item">
                  <button className="btn btn-link nav-link" onClick={logout}>
                    Logout
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
