// src/utils/api.js

import axios from 'axios';

const api = axios.create({
	baseURL: '/api', // Adjust based on your backend route
});

// Include token in headers if available
api.interceptors.request.use((config) => {
	const token = localStorage.getItem('token');
	if (token) {
		config.headers['x-auth-token'] = token;
	}
	return config;
});

export default api;
