// src/components/AdminDashboard/TableStatusPage.jsx

import React, { useEffect, useState } from 'react';
import api from '../../utils/api';

const TableStatusPage = () => {
	const [tables, setTables] = useState([]);

	useEffect(() => {
		fetchTables();
	}, []);

	const fetchTables = async () => {
		try {
			const response = await api.get('/admin/tables');
			setTables(response.data);
		} catch (error) {
			console.error('Error fetching tables:', error);
		}
	};

	const getStatusColor = (status) => {
		switch (status) {
			case 'available':
				return 'success'; // Green
			case 'occupied':
				return 'warning'; // Yellow
			case 'needs_attention':
				return 'danger'; // Red
			default:
				return 'secondary';
		}
	};

	return (
		<div>
			<h2>Table Status</h2>
			<div className="row">
				{tables.map((table) => (
					<div key={table._id} className="col-md-3 mb-3">
						<div className={`card text-white bg-${getStatusColor(table.status)} h-100`}>
							<div className="card-body d-flex flex-column justify-content-center align-items-center">
								<h5 className="card-title">{table.tableNumber}</h5>
								<p className="card-text">
									<span className={`badge bg-light text-dark`}>{table.status}</span>
								</p>
							</div>
						</div>
					</div>
				))}
				{tables.length === 0 && (
					<div className="col-12 text-center">
						<p>No tables available.</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default TableStatusPage;
