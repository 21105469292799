// src/components/OrderDetailsPage/OrderTrackingInterface.jsx

import React, { useContext } from 'react';
import { OrderContext } from '../../context/OrderContext';

const OrderTrackingInterface = ({ order }) => {
	const { updateOrderStatus } = useContext(OrderContext);

	const handleStatusChange = (e) => {
		const newStatus = e.target.value;
		updateOrderStatus(order.kitchenOrderId, newStatus);
	};

	return (
		<div className="mt-4">
			<h4>Update Order Status:</h4>
			<select
				className="form-select"
				value={order.status}
				onChange={handleStatusChange}
				aria-label="Update Order Status"
			>
				<option value="Pending">Pending</option>
				<option value="Preparing">Preparing</option>
				<option value="Ready">Ready</option>
				<option value="Delivered">Delivered</option>
			</select>
		</div>
	);
};

export default OrderTrackingInterface;
