// src/components/Shared/LoginPage.jsx

import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
	const { login } = useContext(AuthContext);
	const navigate = useNavigate();

	const [emailOrUsername, setEmailOrUsername] = useState('');
	const [password, setPassword] = useState('');
	const [errorMsg, setErrorMsg] = useState('');

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await login(emailOrUsername, password);
			// Redirect based on role
			const userRole = JSON.parse(localStorage.getItem('user')).role;
			navigate(`/${userRole}`);
		} catch (error) {
			setErrorMsg(error || 'Invalid credentials. Please try again.');
		}
	};

	return (
		<div className="container mt-5">
			<h2>Staff Login</h2>
			{errorMsg && (
				<div className="alert alert-danger alert-dismissible fade show" role="alert">
					{errorMsg}
					<button type="button" className="btn-close" onClick={() => setErrorMsg('')} aria-label="Close"></button>
				</div>
			)}
			<form onSubmit={handleSubmit}>
				<div className="mb-3">
					<label htmlFor="emailOrUsername" className="form-label">
						Email or Username:
					</label>
					<input
						type="text"
						id="emailOrUsername"
						className="form-control"
						value={emailOrUsername}
						onChange={(e) => setEmailOrUsername(e.target.value)}
						required
						aria-required="true"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="password" className="form-label">
						Password:
					</label>
					<input
						type="password"
						id="password"
						className="form-control"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
						aria-required="true"
					/>
				</div>
				<button type="submit" className="btn btn-primary">
					Login
				</button>
			</form>
		</div>
	);
};

export default LoginPage;
