// src/components/Shared/RegistrationPage.jsx

import React, { useState } from 'react';
import api from '../../utils/api';
import { useNavigate } from 'react-router-dom';

const RegistrationPage = () => {
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		username: '',
		email: '',
		password: '',
		role: 'server', // Default role
	});

	const [errorMsg, setErrorMsg] = useState('');
	const [successMsg, setSuccessMsg] = useState('');

	const { username, email, password, role } = formData;

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrorMsg('');
		setSuccessMsg('');

		// Basic front-end validation
		if (username == "" || email == "" || password == "" || role == "") {
			setErrorMsg('Please fill in all required fields.');
			return;
		}

		try {
			const res = await api.post('/auth/register', formData);
			console.log('Registration response:', res);
			setSuccessMsg('Registration successful! You can now log in.');
			setFormData({
				username: '',
				email: '',
				password: '',
				role: 'server',
			});
			// Optionally, redirect to login page
			navigate('/login');
		} catch (error) {
			console.error('Registration error:', error.response?.data?.msg || error.message);
			setErrorMsg(error.response?.data?.msg || 'Registration failed. Please try again.');
		}
	};

	return (
		<div className="container mt-5">
			<h2>Staff Registration</h2>
			{errorMsg && (
				<div className="alert alert-danger alert-dismissible fade show" role="alert">
					{errorMsg}
					<button type="button" className="btn-close" onClick={() => setErrorMsg('')} aria-label="Close"></button>
				</div>
			)}
			{successMsg && (
				<div className="alert alert-success alert-dismissible fade show" role="alert">
					{successMsg}
					<button type="button" className="btn-close" onClick={() => setSuccessMsg('')} aria-label="Close"></button>
				</div>
			)}
			<form onSubmit={handleSubmit}>
				<div className="mb-3">
					<label htmlFor="username" className="form-label">
						Username:
					</label>
					<input
						type="text"
						id="username"
						name="username"
						className="form-control"
						value={username}
						onChange={handleChange}
						required
						aria-required="true"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="email" className="form-label">
						Email:
					</label>
					<input
						type="email"
						id="email"
						name="email"
						className="form-control"
						value={email}
						onChange={handleChange}
						required
						aria-required="true"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="password" className="form-label">
						Password:
					</label>
					<input
						type="password"
						id="password"
						name="password"
						className="form-control"
						value={password}
						onChange={handleChange}
						required
						aria-required="true"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="role" className="form-label">
						Role:
					</label>
					<select
						id="role"
						name="role"
						className="form-select"
						value={role}
						onChange={handleChange}
						required
						aria-required="true"
					>
						<option value="server">Server</option>
						<option value="kitchen">Kitchen Staff</option>
						{/* Optionally, allow admins to register themselves if needed */}
					</select>
				</div>
				<button type="submit" className="btn btn-primary">
					Register
				</button>
			</form>
		</div>
	);
};

export default RegistrationPage;
