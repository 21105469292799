// src/components/KitchenDashboard/OrderStatusTag.jsx

import React from 'react';

const OrderStatusTag = ({ status }) => {
	const getBadgeClass = (status) => {
		switch (status) {
			case 'Pending':
				return 'bg-warning text-dark';
			case 'Preparing':
				return 'bg-info';
			case 'Ready':
				return 'bg-primary';
			case 'Delivered':
				return 'bg-success';
			default:
				return 'bg-secondary';
		}
	};

	return (
		<span className={`badge ms-3 ${getBadgeClass(status)}`}>
			{status}
		</span>
	);
};

export default OrderStatusTag;
