// src/components/KitchenDashboard/OrderList.jsx

import React from 'react';
import OrderAccordion from './OrderAccordion';

const OrderList = ({ orders }) => {
	return (
		<div className="accordion" id="kitchenOrdersAccordion">
			{orders.length === 0 ? (
				<p>No incoming orders.</p>
			) : (
				orders.map((order) => (
					<OrderAccordion key={order.kitchenOrderId} order={order} />
				))
			)}
		</div>
	);
};

export default OrderList;
