import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../utils/api';

const CustomerLandingPage = () => {
	const [tables, setTables] = useState([]);
	const [kitchens, setKitchens] = useState([]);
	const [selectedKitchen, setSelectedKitchen] = useState('');
	const [filteredTables, setFilteredTables] = useState([]);
	const [selectedTable, setSelectedTable] = useState('');
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const tableId = params.get('tableId');

		if (tableId) {
			// If we have a tableId from the QR code, go directly to the menu
			navigate(`/customer/menu?tableId=${tableId}`);
		} else {
			fetchTables();
		}
	}, [location, navigate]);

	const fetchTables = async () => {
		try {
			const res = await api.get('/customer/tables');
			const allTables = res.data;
			setTables(allTables);

			// Extract unique kitchens
			const uniqueKitchens = [];
			const kitchenNamesMap = {};
			allTables.forEach(table => {
				if (table.assignedKitchen && !kitchenNamesMap[table.assignedKitchen._id]) {
					kitchenNamesMap[table.assignedKitchen._id] = true;
					uniqueKitchens.push(table.assignedKitchen);
				}
			});
			setKitchens(uniqueKitchens);
		} catch (err) {
			console.error('Error fetching tables:', err);
		}
	};

	const handleKitchenChange = (e) => {
		const kitchenId = e.target.value;
		setSelectedKitchen(kitchenId);
		setSelectedTable('');

		// Filter tables based on selectedKitchen
		const tablesForKitchen = tables.filter(t => t.assignedKitchen && t.assignedKitchen._id === kitchenId);
		setFilteredTables(tablesForKitchen);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (selectedTable) {
			navigate(`/customer/menu?tableId=${selectedTable}`);
		}
	};

	return (
		<div className="container mt-5">
			<h2>Welcome to the Banquet</h2>
			<p>Please select your section and then your table:</p>
			<form onSubmit={handleSubmit} className="mb-4">
				<div className="row g-3 align-items-center">
					<div className="col-md-4">
						<select
							className="form-select"
							value={selectedKitchen}
							onChange={handleKitchenChange}
							required
							aria-required="true"
						>
							<option value="" disabled>Select Section</option>
							{kitchens.map((k) => (
								<option key={k._id} value={k._id}>{k.name}</option>
							))}
						</select>
					</div>
					<div className="col-md-4">
						<select
							className="form-select"
							value={selectedTable}
							onChange={(e) => setSelectedTable(e.target.value)}
							required
							aria-required="true"
							disabled={!selectedKitchen}
						>
							<option value="" disabled>Select Table</option>
							{filteredTables.map((table) => (
								<option key={table._id} value={table._id}>
									Table {table.tableNumber}
								</option>
							))}
						</select>
					</div>
					<div className="col-md-2">
						<button type="submit" className="btn btn-primary w-100" disabled={!selectedTable}>
							Go
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default CustomerLandingPage;
