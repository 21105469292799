// src/components/ServerDashboard/ServerDashboard.jsx

import React from 'react';
import TableList from './TableList';
import ReportAlerts from './ReportAlerts';

const ServerDashboard = () => {
	return (
		<div className="container-fluid p-4">
			<h1>Server Dashboard</h1>
			<hr />
			<ReportAlerts />
			<TableList />
		</div>
	);
};

export default ServerDashboard;
