import React, { useState, useEffect } from 'react';

const AllergyModal = ({ show, handleClose, allergies, onToggleAllergy }) => {
	const [selectedAllergies, setSelectedAllergies] = useState(allergies);

	const availableAllergies = ['Peanuts', 'Shellfish', 'Gluten', 'Dairy', 'Soy'];

	useEffect(() => {
		setSelectedAllergies(allergies);
	}, [allergies]);

	const handleCheckboxChange = (allergy) => {
		onToggleAllergy(allergy); // Update backend and parent state
		setSelectedAllergies((prev) =>
			prev.includes(allergy) ? prev.filter((a) => a !== allergy) : [...prev, allergy]
		);
	};

	const handleSave = () => {
		handleClose();
	};

	if (!show) return null;

	return (
		<div
			className="modal show fade d-block"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="allergy-modal-title"
			aria-modal="true"
			onClick={handleClose}
		>
			<div
				className="modal-dialog"
				role="document"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="allergy-modal-title">Select Your Allergies</h5>
						<button type="button" className="close btn" aria-label="Close" onClick={handleClose}>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<form>
							{availableAllergies.map((allergy) => (
								<div className="form-check" key={allergy}>
									<input
										className="form-check-input"
										type="checkbox"
										id={`allergy-${allergy}`}
										checked={selectedAllergies.includes(allergy)}
										onChange={() => handleCheckboxChange(allergy)}
									/>
									<label className="form-check-label" htmlFor={`allergy-${allergy}`}>
										{allergy}
									</label>
								</div>
							))}
						</form>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-secondary" onClick={handleClose}>
							Close
						</button>
						<button type="button" className="btn btn-primary" onClick={handleSave}>
							Save Allergies
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AllergyModal;
