// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthProvider } from './context/AuthContext';
import { OrderProvider } from './context/OrderContext';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS (includes Popper.js)

ReactDOM.render(
	<React.StrictMode>
		<AuthProvider>
			<OrderProvider>
				<App />
			</OrderProvider>
		</AuthProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
