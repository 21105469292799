// src/components/AdminDashboard/AdminKitchenManagement.jsx

import React, { useEffect, useState } from 'react';
import api from '../../utils/api';

const AdminKitchenManagement = () => {
	const [kitchens, setKitchens] = useState([]);
	const [users, setUsers] = useState([]);
	const [selectedKitchen, setSelectedKitchen] = useState(null);
	const [assignedStaff, setAssignedStaff] = useState([]);
	const [availableStaff, setAvailableStaff] = useState([]);
	const [allMeals, setAllMeals] = useState([]); // Global list of meals
	const [availableMeals, setAvailableMeals] = useState([]);
	const [errorMsg, setErrorMsg] = useState('');
	const [successMsg, setSuccessMsg] = useState('');

	useEffect(() => {
		fetchKitchens();
		fetchUsers();
		fetchAllMeals();
	}, []);

	const fetchKitchens = async () => {
		try {
			// Assume kitchens are populated with assignedStaff and assignedMeals
			const res = await api.get('/admin/kitchens');
			setKitchens(res.data);
		} catch (error) {
			console.error('Error fetching kitchens:', error.response?.data?.msg || error.message);
			setErrorMsg(error.response?.data?.msg || 'Failed to fetch kitchens.');
		}
	};

	const fetchUsers = async () => {
		try {
			const res = await api.get('/admin/users');
			setUsers(res.data.filter((user) => user.role === 'kitchen')); // Only kitchen staff
		} catch (error) {
			console.error('Error fetching users:', error.response?.data?.msg || error.message);
			setErrorMsg(error.response?.data?.msg || 'Failed to fetch users.');
		}
	};

	const fetchAllMeals = async () => {
		try {
			const res = await api.get('/admin/meals');
			setAllMeals(res.data);
		} catch (error) {
			console.error('Error fetching meals:', error.response?.data?.msg || error.message);
			setErrorMsg(error.response?.data?.msg || 'Failed to fetch meals.');
		}
	};

	const handleKitchenSelect = (e) => {
		const kitchenId = e.target.value;
		const kitchen = kitchens.find((k) => k._id === kitchenId);
		setSelectedKitchen(kitchen);
		setAssignedStaff(kitchen.assignedStaff || []);

		// Determine available staff
		const allAssignedUserIds = kitchens.reduce((acc, k) => {
			const assigned = k.assignedStaff || [];
			return acc.concat(assigned.map(st => st._id));
		}, []);
		const filteredAvailableStaff = users.filter((user) => !allAssignedUserIds.includes(user._id));
		setAvailableStaff(filteredAvailableStaff);

		// Determine assigned meals and available meals
		const assignedMealIds = kitchen.assignedMeals ? kitchen.assignedMeals.map(m => m._id) : [];
		const avMeals = allMeals.filter(m => !assignedMealIds.includes(m._id));
		setAvailableMeals(avMeals);
	};

	const handleAssignStaff = async (userId) => {
		if (!selectedKitchen) return;
		try {
			await api.post(`/admin/kitchens/${selectedKitchen._id}/assign-staff`, { staffId: userId });
			setSuccessMsg('Staff assigned successfully.');
			await fetchKitchens();
			await fetchUsers();
			if (selectedKitchen) handleKitchenSelect({ target: { value: selectedKitchen._id } });
		} catch (error) {
			console.error('Error assigning staff:', error.response?.data?.msg || error.message);
			setErrorMsg(error.response?.data?.msg || 'Failed to assign staff.');
		}
	};

	const handleRemoveStaff = async (userId) => {
		if (!selectedKitchen) return;
		try {
			await api.delete(`/admin/kitchens/${selectedKitchen._id}/assigned-staff/${userId}`);
			setSuccessMsg('Staff removed successfully.');
			await fetchKitchens();
			await fetchUsers();
			if (selectedKitchen) handleKitchenSelect({ target: { value: selectedKitchen._id } });
		} catch (error) {
			console.error('Error removing staff:', error.response?.data?.msg || error.message);
			setErrorMsg(error.response?.data?.msg || 'Failed to remove staff.');
		}
	};

	const handleAssignMeal = async (mealId) => {
		if (!selectedKitchen) return;
		try {
			// Assume POST /admin/kitchens/:kitchenId/assign-meal { mealId }
			await api.post(`/admin/kitchens/${selectedKitchen._id}/assign-meal`, { mealId });
			setSuccessMsg('Meal assigned successfully.');
			await fetchKitchens();
			if (selectedKitchen) handleKitchenSelect({ target: { value: selectedKitchen._id } });
		} catch (error) {
			console.error('Error assigning meal:', error.response?.data?.msg || error.message);
			setErrorMsg(error.response?.data?.msg || 'Failed to assign meal.');
		}
	};

	const handleRemoveMeal = async (mealId) => {
		if (!selectedKitchen) return;
		try {
			// Assume DELETE /admin/kitchens/:kitchenId/assigned-meals/:mealId
			await api.delete(`/admin/kitchens/${selectedKitchen._id}/assigned-meals/${mealId}`);
			setSuccessMsg('Meal removed successfully.');
			await fetchKitchens();
			if (selectedKitchen) handleKitchenSelect({ target: { value: selectedKitchen._id } });
		} catch (error) {
			console.error('Error removing meal:', error.response?.data?.msg || error.message);
			setErrorMsg(error.response?.data?.msg || 'Failed to remove meal.');
		}
	};

	return (
		<div>
			<h2>Kitchen Management</h2>

			{errorMsg && (
				<div className="alert alert-danger alert-dismissible fade show" role="alert">
					{errorMsg}
					<button type="button" className="btn-close" onClick={() => setErrorMsg('')} aria-label="Close"></button>
				</div>
			)}
			{successMsg && (
				<div className="alert alert-success alert-dismissible fade show" role="alert">
					{successMsg}
					<button type="button" className="btn-close" onClick={() => setSuccessMsg('')} aria-label="Close"></button>
				</div>
			)}

			<div className="mb-4">
				<label htmlFor="kitchenSelect" className="form-label">Select Kitchen:</label>
				<select
					id="kitchenSelect"
					className="form-select"
					onChange={handleKitchenSelect}
					defaultValue=""
				>
					<option value="" disabled>-- Select Kitchen --</option>
					{kitchens.map((kitchen) => (
						<option key={kitchen._id} value={kitchen._id}>{kitchen.name}</option>
					))}
				</select>
			</div>

			{selectedKitchen && (
				<>
					{/* Assigned Staff */}
					<div className="mb-4">
						<h4>Assigned Staff for {selectedKitchen.name}</h4>
						{assignedStaff.length > 0 ? (
							<ul className="list-group">
								{assignedStaff.map((staffObj) => (

									< li key={staffObj._id} className="list-group-item d-flex justify-content-between align-items-center" >
										{staffObj.name}
										<button button className="btn btn-danger btn-sm" onClick={() => handleRemoveStaff(staffObj._id)}>
											Remove
										</button>
									</li>
								))}
							</ul>
						) : (
							<p>No staff assigned to this kitchen.</p>
						)}
					</div>

					{/* Available Staff */}
					<div className="mb-4">
						<h4>Available Staff to Assign</h4>
						{availableStaff.length > 0 ? (
							<ul className="list-group">
								{availableStaff.map((staff) => (
									<li key={staff._id} className="list-group-item d-flex justify-content-between align-items-center">
										{staff.name}
										<button className="btn btn-primary btn-sm" onClick={() => handleAssignStaff(staff._id)}>
											Assign
										</button>
									</li>
								))}
							</ul>
						) : (
							<p>No available staff to assign.</p>
						)}
					</div>

					{/* Assigned Tables */}
					<div className="mb-4">
						<h4>Assigned Tables for {selectedKitchen.name}</h4>
						{selectedKitchen.assignedTables && selectedKitchen.assignedTables.length > 0 ? (
							<ul className="list-group">
								{selectedKitchen.assignedTables.map((tableObj) => (
									<li key={tableObj._id} className="list-group-item d-flex justify-content-between align-items-center">
										Table: {tableObj.tableNumber}
									</li>
								))}
							</ul>
						) : (
							<p>No tables assigned to this kitchen.</p>
						)}
					</div>

					{/* Assigned Meals */}
					<div className="mb-4">
						<h4>Assigned Meals for {selectedKitchen.name}</h4>
						{selectedKitchen.assignedMeals && selectedKitchen.assignedMeals.length > 0 ? (
							<ul className="list-group">
								{selectedKitchen.assignedMeals.map((meal) => (
									<li key={meal._id} className="list-group-item d-flex justify-content-between align-items-center">
										{meal.name}
										<button className="btn btn-danger btn-sm" onClick={() => handleRemoveMeal(meal._id)}>
											Remove
										</button>
									</li>
								))}
							</ul>
						) : (
							<p>No meals assigned to this kitchen.</p>
						)}
					</div>

					{/* Available Meals to Assign */}
					<div className="mb-4">
						<h4>Available Meals to Assign</h4>
						{availableMeals.length > 0 ? (
							<ul className="list-group">
								{availableMeals.map((meal) => (
									<li key={meal._id} className="list-group-item d-flex justify-content-between align-items-center">
										{meal.name}
										<button className="btn btn-primary btn-sm" onClick={() => handleAssignMeal(meal._id)}>
											Assign
										</button>
									</li>
								))}
							</ul>
						) : (
							<p>No available meals to assign.</p>
						)}
					</div>
				</>
			)}
		</div >
	);
};

export default AdminKitchenManagement;
