// src/App.jsx

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import NavigationBar from './components/Shared/NavigationBar';
import CustomerLandingPage from './components/CustomerOrderPage/CustomerLandingPage'; // New component
import CustomerOrderPage from './components/CustomerOrderPage/CustomerOrderPage';
import ServerDashboard from './components/ServerDashboard/ServerDashboard';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import KitchenDashboard from './components/KitchenDashboard/KitchenDashboard';
import OrderDetailsPage from './components/OrderDetailsPage/OrderDetailsPage';
import LoginPage from './components/Shared/LoginPage';
import RegistrationPage from './components/Shared/RegistrationPage';
import PrivateRoute from './components/PrivateRoute';

function App() {
	return (
		<Router>
			<NavigationBar />
			<Routes>
				{/* Public Routes for Customer */}
				<Route path="/" element={<CustomerLandingPage />} />
				<Route path="/customer/menu" element={<CustomerOrderPage />} />

				{/* Registration Route */}
				<Route path="/register" element={<RegistrationPage />} />

				{/* Staff Routes */}
				<Route path="/login" element={<LoginPage />} />
				<Route path="/server" element={<PrivateRoute component={ServerDashboard} allowedRoles={['server']} />} />
				<Route path="/kitchen/:kitchenId" element={<PrivateRoute component={KitchenDashboard} allowedRoles={['kitchen']} />} />
				<Route path="/order/:orderId" element={<PrivateRoute component={OrderDetailsPage} />} />

				{/* Admin Routes */}
				<Route path="/admin/*" element={<PrivateRoute component={AdminDashboard} allowedRoles={['admin']} />} />

				{/* Default Route */}
				<Route path="/" element={<Navigate to="/customer/landing" replace />} />
				<Route path="*" element={<h2>404: Page Not Found</h2>} />
			</Routes>
		</Router>
	);
}

export default App;
