// src/components/CustomerOrderPage.jsx

import React, { useState, useEffect } from 'react';
import AllergyModal from './AllergyModal';
import ItemList from './ItemList';
import SelectedItemsSidebar from './SelectedItemsSidebar';
import OrderStatus from './OrderStatus';
import IssueReportModal from './IssueReportModal';
import { useLocation, Navigate } from 'react-router-dom';
import api from '../../utils/api';

const CustomerOrderPage = () => {
	const [showAllergyModal, setShowAllergyModal] = useState(false);
	const [showIssueModal, setShowIssueModal] = useState(false);
	const [error, setError] = useState(null);
	const [selectedItems, setSelectedItems] = useState([]);
	const [allergies, setAllergies] = useState([]);
	const [orderId, setOrderId] = useState(null);
	const [orderStatus, setOrderStatus] = useState('Draft');

	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const tableId = params.get('tableId');

	useEffect(() => {
		if (!tableId) return;

		const storedOrderId = localStorage.getItem('currentOrderId');

		if (storedOrderId) {
			// Try to fetch the existing order
			fetchExistingOrder(storedOrderId);
		} else {
			// No existing order, create a new one
			createNewOrder(tableId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableId]);

	const fetchExistingOrder = async (existingOrderId) => {
		try {
			const res = await api.get(`/customer/orders/${existingOrderId}`);
			const order = res.data;
			setOrderId(order._id);
			setSelectedItems(order.items.map(i => ({
				...i.menuItem,
				quantity: i.quantity,
				customization: i.customization
			})));
			setAllergies(order.allergies || []);
			setOrderStatus(order.status);
		} catch (err) {
			console.error('Error fetching existing order:', err);
			setError('Failed to load existing order. Creating a new one.');
			localStorage.removeItem('currentOrderId');
			createNewOrder(tableId);
		}
	};

	const createNewOrder = async (tableId) => {
		try {
			const res = await api.post('/customer/orders', {
				tableId,
				items: [],
				allergies: [],
			});
			setOrderId(res.data._id);
			localStorage.setItem('currentOrderId', res.data._id);
			setSelectedItems(res.data.items || []);
			setAllergies(res.data.allergies || []);
			setOrderStatus(res.data.status || 'Draft');
		} catch (err) {
			console.error('Error creating order:', err);
			setError('Failed to create order. Please try again.');
		}
	};

	if (!tableId) {
		return <Navigate to="/customer/landing" replace />;
	}

	const handleAddItem = async (item) => {
		try {
			const updatedItems = [...selectedItems, { ...item, quantity: 1 }];
			await api.put(`/customer/orders/${orderId}`, {
				items: updatedItems.map(i => ({
					menuItemId: i._id,
					quantity: i.quantity,
					customization: i.customization || ''
				}))
			});
			setSelectedItems(updatedItems);
		} catch (err) {
			console.error('Error adding item:', err);
			setError('Failed to add item.');
		}
	};

	const handleRemoveItem = async (itemId) => {
		try {
			const updatedItems = selectedItems.filter(i => (i.id || i._id) !== itemId);
			await api.put(`/customer/orders/${orderId}`, {
				items: updatedItems.map(i => ({
					menuItemId: i._id,
					quantity: i.quantity,
					customization: i.customization || ''
				}))
			});
			setSelectedItems(updatedItems);
		} catch (err) {
			console.error('Error removing item:', err);
			setError('Failed to remove item.');
		}
	};

	const handleToggleAllergy = async (allergy) => {
		let updatedAllergies;
		if (allergies.includes(allergy)) {
			updatedAllergies = allergies.filter(a => a !== allergy);
		} else {
			updatedAllergies = [...allergies, allergy];
		}

		try {
			await api.post(`/customer/orders/${orderId}/allergies`, { allergies: updatedAllergies });
			setAllergies(updatedAllergies);
		} catch (err) {
			console.error('Error updating allergies:', err);
			setError('Failed to update allergies.');
		}
	};

	const handleReportIssue = async (issue) => {
		try {
			await api.post(`/customer/orders/${orderId}/issues`, { issue });
		} catch (err) {
			console.error('Error reporting issue:', err);
			setError('Failed to report issue.');
		}
	};

	const handleCompleteOrder = async () => {
		// Finalize the order by marking it as Pending
		try {
			const res = await api.put(`/customer/orders/${orderId}/status`, { status: 'Pending' });
			setOrderStatus(res.data.status);
			alert('Your order has been submitted! A server will be with you soon.');
		} catch (err) {
			console.error('Error finalizing order:', err);
			setError('Failed to finalize order.');
		}
	};

	return (
		<div className="container-fluid p-4">
			<div className="row mb-4">
				<div className="col">
					<h1>Menu for Your Table</h1>
				</div>
			</div>

			{error && (
				<div className="row">
					<div className="col">
						<div className="alert alert-danger alert-dismissible fade show" role="alert">
							{error}
							<button type="button" className="close" onClick={() => setError(null)} aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					</div>
				</div>
			)}

			<div className="row">
				<div className="col-md-8">
					<button
						className="btn btn-info mb-3"
						onClick={() => setShowAllergyModal(true)}
						aria-haspopup="dialog"
					>
						Select Allergies
					</button>
					<ItemList
						tableId={tableId}
						selectedItems={selectedItems}
						onAddItem={handleAddItem}
					/>
				</div>
				<div className="col-md-4">
					<SelectedItemsSidebar
						selectedItems={selectedItems}
						onRemoveItem={handleRemoveItem}
					/>
					<OrderStatus orderStatus={orderStatus} />
					<div className="mt-3">
						<button
							className="btn btn-success me-2"
							onClick={handleCompleteOrder}
							disabled={selectedItems.length === 0}
							aria-disabled={selectedItems.length === 0}
							aria-label="Finalize Selection"
						>
							Finalize Selection
						</button>
						<button
							className="btn btn-warning"
							onClick={() => setShowIssueModal(true)}
							aria-label="Report Issue"
						>
							Report Issue
						</button>
					</div>
				</div>
			</div>

			<AllergyModal
				show={showAllergyModal}
				handleClose={() => setShowAllergyModal(false)}
				allergies={allergies}
				onToggleAllergy={handleToggleAllergy}
			/>
			<IssueReportModal
				show={showIssueModal}
				handleClose={() => setShowIssueModal(false)}
				onReportIssue={handleReportIssue}
				error={error}
				setError={setError}
			/>
		</div>
	);
};

export default CustomerOrderPage;
