// src/components/ServerDashboard/ReportAlerts.jsx

import React, { useContext } from 'react';
import { OrderContext } from '../../context/OrderContext';

const ReportAlerts = () => {
	const { assignedTables, resolveReport } = useContext(OrderContext);

	// Aggregate all unresolved reports
	const unresolvedReports = assignedTables.flatMap((table) =>
		table.reports
			.filter((report) => !report.resolved)
			.map((report) => ({ ...report, tableNumber: table.tableNumber }))
	);

	if (unresolvedReports.length === 0) return null;

	return (
		<div className="alert alert-warning alert-dismissible fade show" role="alert">
			<h4 className="alert-heading">New Reports</h4>
			<hr />
			<ul className="mb-0">
				{unresolvedReports.map((report) => (
					<li key={report.reportId}>
						<strong>{report.tableNumber}:</strong> {report.message}
						<button
							className="btn btn-sm btn-outline-success ms-3"
							onClick={() => resolveReport(getTableId(report.tableNumber), report.reportId)}
						>
							Mark as Resolved
						</button>
					</li>
				))}
			</ul>
			<button type="button" className="btn-close" onClick={() => { }} aria-label="Close"></button>
		</div>
	);

	// Helper function to get tableId from tableNumber
	function getTableId(tableNumber) {
		const table = assignedTables.find((t) => t.tableNumber === tableNumber);
		return table ? table.tableId : null;
	}
};

export default ReportAlerts;
