import React from 'react';

const SelectedItemsSidebar = ({ selectedItems, onRemoveItem }) => {
	return (
		<div>
			<h4>Selected Items</h4>
			{selectedItems.length === 0 ? (
				<p>No items selected.</p>
			) : (
				<ul className="list-group">
					{selectedItems.map((item) => (
						<li
							key={item.id || item._id}
							className="list-group-item d-flex justify-content-between align-items-center"
						>
							<div>
								<strong>{item.name}</strong>
							</div>
							<button
								className="btn btn-danger btn-sm"
								onClick={() => onRemoveItem(item.id || item._id)}
								aria-label={`Remove ${item.name}`}
							>
								&times;
							</button>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default SelectedItemsSidebar;
