// src/components/ServerDashboard/OrderItem.jsx

import React from 'react';

const OrderItem = ({ tableId, orderId, item, markItemAsOrdered }) => {
	const handleMark = () => {
		markItemAsOrdered(tableId, orderId, item.itemId);
	};

	return (
		<li className="list-group-item d-flex justify-content-between align-items-center">
			<span>
				{item.name}
			</span>
			<div>
				{item.ordered ? (
					<span className="badge bg-success">Ordered</span>
				) : (
					<button className="btn btn-sm btn-primary" onClick={handleMark}>
						Mark as Ordered
					</button>
				)}
			</div>
		</li>
	);
};

export default OrderItem;
