// src/components/AdminDashboard/MealManagement.jsx

import React, { useEffect, useState } from 'react';
import api from '../../utils/api';

const MealManagement = () => {
	const [meals, setMeals] = useState([]);
	const [newMeal, setNewMeal] = useState({
		name: '',
		category: '',
		description: '',
		quantity: 0,
		threshold: 10,
	});
	const [editingMealId, setEditingMealId] = useState(null);
	const [editingMeal, setEditingMeal] = useState({
		name: '',
		category: '',
		description: '',
		quantity: 0,
		threshold: 10,
	});

	useEffect(() => {
		fetchMeals();
	}, []);

	const fetchMeals = async () => {
		try {
			const response = await api.get('/admin/meals');
			setMeals(response.data);
		} catch (error) {
			console.error('Error fetching meals:', error);
		}
	};

	const handleAddMeal = async (e) => {
		e.preventDefault();
		const { name, category } = newMeal;
		if (name && category) {
			try {
				await api.post('/admin/meals', newMeal);
				setNewMeal({
					name: '',
					category: '',
					description: '',
					quantity: 0,
					threshold: 10,
				});
				await fetchMeals();
			} catch (error) {
				console.error('Error adding meal:', error);
			}
		}
	};

	const handleUpdateMeal = async (e) => {
		e.preventDefault();
		const { name, category } = editingMeal;
		if (name && category) {
			try {
				await api.put(`/admin/meals/${editingMealId}`, editingMeal);
				setEditingMealId(null);
				setEditingMeal({
					name: '',
					category: '',
					description: '',
					quantity: 0,
					threshold: 10,
				});
				await fetchMeals();
			} catch (error) {
				console.error('Error updating meal:', error);
			}
		}
	};

	const handleDeleteMeal = async (mealId) => {
		if (window.confirm('Are you sure you want to delete this meal?')) {
			try {
				await api.delete(`/admin/meals/${mealId}`);
				await fetchMeals();
			} catch (error) {
				console.error('Error deleting meal:', error);
			}
		}
	};

	const startEditing = (meal) => {
		setEditingMealId(meal._id);
		setEditingMeal({
			name: meal.name,
			category: meal.category,
			description: meal.description,
			quantity: meal.quantity,
			threshold: meal.threshold,
		});
	};

	const cancelEditing = () => {
		setEditingMealId(null);
		setEditingMeal({
			name: '',
			category: '',
			description: '',
			quantity: 0,
			threshold: 10,
		});
	};

	return (
		<div>
			<h2>Meal Management</h2>
			<p>All kitchens share this global menu. Availability depends on the meal's own quantity.</p>

			{/* Add New Meal */}
			<form onSubmit={handleAddMeal} className="mb-4">
				<h4>Add New Meal</h4>
				<div className="row g-3">
					<div className="col-md-3">
						<input
							type="text"
							className="form-control"
							placeholder="Meal Name"
							value={newMeal.name}
							onChange={(e) => setNewMeal({ ...newMeal, name: e.target.value })}
							required
							aria-required="true"
						/>
					</div>
					<div className="col-md-3">
						<select
							className="form-select"
							value={newMeal.category}
							onChange={(e) => setNewMeal({ ...newMeal, category: e.target.value })}
							required
							aria-required="true"
						>
							<option value="" disabled>
								Select Category
							</option>
							<option value="Hors d'oeuvres">Hors d'oeuvres</option>
							<option value="Soup">Soup</option>
							<option value="Appetizer">Appetizer</option>
							<option value="Entrée">Entrée</option>
							<option value="Accompaniment">Accompaniment</option>
							<option value="Dessert">Dessert</option>
							<option value="After-Hours">After-Hours</option>
							<option value="Oriental">Oriental</option>
							<option value="Local Cuisine">Local Cuisine</option>
						</select>
					</div>
					<div className="col-md-3">
						<input
							type="number"
							className="form-control"
							placeholder="Quantity"
							value={newMeal.quantity}
							onChange={(e) => setNewMeal({ ...newMeal, quantity: parseInt(e.target.value) })}
							min="0"
							required
							aria-required="true"
						/>
					</div>
					<div className="col-md-3">
						<input
							type="number"
							className="form-control"
							placeholder="Threshold"
							value={newMeal.threshold}
							onChange={(e) => setNewMeal({ ...newMeal, threshold: parseInt(e.target.value) })}
							min="0"
							required
							aria-required="true"
						/>
					</div>
					<div className="col-md-12 mt-2">
						<input
							type="text"
							className="form-control"
							placeholder="Description"
							value={newMeal.description}
							onChange={(e) => setNewMeal({ ...newMeal, description: e.target.value })}
						/>
					</div>
					<div className="col-md-12 mt-2">
						<button type="submit" className="btn btn-primary w-100">
							Add Meal
						</button>
					</div>
				</div>
			</form>

			{/* Meals List */}
			<table className="table table-bordered">
				<thead>
					<tr>
						<th>Meal Name</th>
						<th>Category</th>
						<th>Description</th>
						<th>Quantity</th>
						<th>Threshold</th>
						<th>Availability</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{meals.length > 0 ? (
						meals.map((meal) =>
							editingMealId === meal._id ? (
								<tr key={meal._id}>
									<td colSpan="7">
										<form onSubmit={handleUpdateMeal}>
											<div className="row g-3 align-items-center">
												<div className="col-md-3">
													<input
														type="text"
														className="form-control"
														value={editingMeal.name}
														onChange={(e) => setEditingMeal({ ...editingMeal, name: e.target.value })}
														required
														aria-required="true"
													/>
												</div>
												<div className="col-md-3">
													<select
														className="form-select"
														value={editingMeal.category}
														onChange={(e) => setEditingMeal({ ...editingMeal, category: e.target.value })}
														required
														aria-required="true"
													>
														<option value="" disabled>
															Select Category
														</option>
														<option value="Hors d'oeuvres">Hors d'oeuvres</option>
														<option value="Soup">Soup</option>
														<option value="Appetizer">Appetizer</option>
														<option value="Entrée">Entrée</option>
														<option value="Accompaniment">Accompaniment</option>
														<option value="Dessert">Dessert</option>
														<option value="After-Hours">After-Hours</option>
														<option value="Oriental">Oriental</option>
														<option value="Local Cuisine">Local Cuisine</option>
													</select>
												</div>
												<div className="col-md-3">
													<input
														type="number"
														className="form-control"
														value={editingMeal.quantity}
														onChange={(e) => setEditingMeal({ ...editingMeal, quantity: parseInt(e.target.value) })}
														min="0"
														required
														aria-required="true"
													/>
												</div>
												<div className="col-md-3">
													<input
														type="number"
														className="form-control"
														value={editingMeal.threshold}
														onChange={(e) => setEditingMeal({ ...editingMeal, threshold: parseInt(e.target.value) })}
														min="0"
														required
														aria-required="true"
													/>
												</div>
												<div className="col-md-12 mt-2">
													<input
														type="text"
														className="form-control"
														value={editingMeal.description}
														onChange={(e) => setEditingMeal({ ...editingMeal, description: e.target.value })}
														placeholder="Description"
													/>
												</div>
												<div className="col-md-12 mt-2">
													<button type="submit" className="btn btn-success w-100">
														Save
													</button>
													<button type="button" className="btn btn-secondary w-100 mt-2" onClick={cancelEditing}>
														Cancel
													</button>
												</div>
											</div>
										</form>
									</td>
								</tr>
							) : (
								<tr key={meal._id}>
									<td>{meal.name}</td>
									<td>{meal.category}</td>
									<td>{meal.description}</td>
									<td>{meal.quantity}</td>
									<td>{meal.threshold}</td>
									<td>{meal.quantity > 0 ? 'Available' : 'Unavailable'}</td>
									<td>
										<button className="btn btn-sm btn-warning me-2" onClick={() => startEditing(meal)}>
											Edit
										</button>
										<button className="btn btn-sm btn-danger" onClick={() => handleDeleteMeal(meal._id)}>
											Delete
										</button>
									</td>
								</tr>
							)
						)
					) : (
						<tr>
							<td colSpan="7" className="text-center">
								No meals available.
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default MealManagement;
