import React, { useState, useEffect } from 'react';

const IssueReportModal = ({ show, handleClose, onReportIssue, error, setError }) => {
  const [issue, setIssue] = useState('');

  useEffect(() => {
    if (!show) {
      setIssue('');
      setError(null);
    }
  }, [show, setError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (issue.trim() === '') {
      setError('Issue description cannot be empty.');
      return;
    }
    await onReportIssue(issue);
    setIssue('');
    handleClose();
  };

  if (!show) return null;

  return (
    <div
      className="modal show fade d-block"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="issue-report-modal-title"
      aria-modal="true"
      onClick={handleClose}
    >
      <div
        className="modal-dialog"
        role="document"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="issue-report-modal-title">Report an Issue</h5>
            <button type="button" className="close btn" aria-label="Close" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {error && (
              <div className="alert alert-danger alert-dismissible fade show" role="alert">
                {error}
                <button type="button" className="close" onClick={() => setError(null)} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="issueDescription">Describe the issue:</label>
                <textarea
                  className="form-control"
                  id="issueDescription"
                  rows="3"
                  value={issue}
                  onChange={(e) => setIssue(e.target.value)}
                  required
                  aria-required="true"
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary mt-3">Submit Issue</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IssueReportModal;
